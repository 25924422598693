<template>
    <div class="app-container stadium-calendar">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="handleFix">预定会议</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
        </div>
        <el-divider></el-divider>
        <div class="calendar-contant">
            <!-- 头部 -->
            <div class="contantTitle">
                <p>
                    <i class="el-icon-s-order" style="font-size: 20px; color: #1A4CEC; margin-right: 3px;"></i>
                    <!-- <span style="font-size: 20px;">2023年4月9日 - 4月15日</span> -->
                    <span style="font-size: 20px;">{{ firstDay }} - {{ lasttDay }}</span>
                    <i class="el-icon-arrow-left"
                        style="border: 1px solid #ccc; padding: 3px; margin: 0 16px; cursor: pointer;"
                        @click="lastWeek"></i>
                    <i class="el-icon-arrow-right" style="border: 1px solid #ccc; padding: 3px; cursor: pointer;"
                        @click="nextWeek"></i>
                </p>
            </div>
            <!-- 日历 -->
            <div class="contantTable">
                <table v-loading="tableLoading" element-loading-text="loading">
                    <thead>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" v-for="(item, index) in md" :key="index"> {{ item }} ({{
                                week[index] }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-for="item in siteName" :key="item.id"> -->
                        <template v-if="filteredSiteName.length > 0">
                            <tr v-for="item in filteredSiteName" :key="item.id">
                                <th style="text-align: center; vertical-align: middle;">
                                    <!-- <p class="table-first-col">{{ item.siteName || item.siteType === siteType }}</p> -->
                                    <p>{{ item.conferenceName }}</p>
                                </th>
                                <td v-for="(i, index) in timeQuantum" :key="index">
                                    <div v-for="(ii, index) in i[Yms[index]]" :key="index + 'a'">
                                        <p v-for="(iii, index) in ii[item.conferenceName]" :key="index + 'b'" :class="i.cssType"
                                            @click="timeClick(i, ii, iii)">{{ iii }}</p>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <!-- <template v-else>
                            <tr>
                                <td colspan="8" style="text-align: center;">暂无数据</td>
                            </tr>
                        </template> -->
                    </tbody>
                </table>
            </div>
        </div>

        <el-dialog :visible.sync="suggestionObj.dialogVisible" :before-close="handleClose" width="900px"
            :destroy-on-close="true" :close-on-click-modal="false">
            <template slot="title">
                <span>{{ suggestionObj.dialogTitle }}</span>
            </template>
            <meetingDialog v-if="suggestionObj.dialogVisible" ref="meetingDialog" :dialogStatus="suggestionObj.dialogStatus"
                :id="suggestionObj.id" :detailsList = suggestionObj.detailsList @close="handleClose" :suggestionObj = "suggestionObj"></meetingDialog>
        </el-dialog>
    </div>
</template>

<script>
// import {
//     getScheduleList,
//     getPlaceList,

// } from "@/api/ruge/gsPark/customerService/MeetingRoom";

import {
    getScheduleList,
    getPlaceList,
} from "@/api/ruge/lego/meetingRoom/meetingRoom"
import { getProjectList } from "@/api/business/base/tenant/map";
import meetingDialog from "./meetingDialog.vue"
export default {
    name: "calendarComponent",
    components: {
        meetingDialog
    },
    data() {
        return {
            ruleFormDialog: {
                cancelReason: '',
            },
            rulesDialog: {
                cancelReason: [
                    { required: true, message: '请输入取消原因', trigger: 'blur' },
                    { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                ]
            },
            // 年月日 以及对应的周期
            md: [],
            week: [],
            firstDay: '',
            lasttDay: '',
            siteName: [], //获取场地列表
            timeQuantum: [], // 获取日历时间段列表 
            Yms: [],//当前一周的年月日
            tabs: ['篮球场', '网球场'],
            activeTab: 0,
            siteType: 'basketball',
            tableLoading: false,
            dayNum: 0,
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            detailsObj: {},
            cancelReasonId: '',
            cancelShow: false,
            cancelchecked: false,
            suggestionObj: {
                tableLoading: false,
                dialogVisible: false,
                dialogTitle: "新增会议预定",
                dialogStatus: "add",
                detailsList: {},
                listQuery: {
                    current: 1,
                    rowCount: 10,
                }
            },
        }
    },
    created() {
        this.getWeekList()
        this.getTableList()
        this.getProjectList()
    },
    updated() {
        const today = Date.now();
        const tadayStartTimestamp = new Date().setHours(0, 0, 0, 0);
        const tadayEndTimestamp = new Date().setHours(23, 59, 59, 999);
        // console.log(tadayStartTimestamp, tadayEndTimestamp, '---');
        // console.log(today, 'today');
        this.timeQuantum.forEach((item) => {
            const date = new Date(Object.keys(item)[0].toString()).getTime()
            // console.log(date, 'date');
            if (date > tadayEndTimestamp) {
                // console.log('大');
                // item.isDestined = 'light';
                this.$set(item, 'cssType', 'light');
            } else if (date < tadayStartTimestamp) {
                // console.log('小');
                // item.isDestined = 'grey';
                this.$set(item, 'cssType', 'grey');
            } else {
                // console.log('等于');
                // this.$set(item, 'cssType', '');
            }
        })
    },
    methods: {
        getProjectList() {
            getProjectList().then(res => {
                let compantList = []
                res.forEach(item => {
                    compantList.push({
                        label: item.projectName,
                        value: item.projectId
                    })
                })
                this.suggestionObj.projectList = compantList
            })
        },
        // 初始化数据
        getTableList() {
            this.tableLoading = true
            // 获取时间段
            getScheduleList(
                this.Yms,
                // dates: ["2023-05-22","2023-05-23","2023-05-24","2023-05-25","2023-05-26","2023-05-27","2023-05-29"],
                // siteType: 'basketball'
                // siteType: this.siteType
            ).then((res) => {
                // console.log(res, '日历列表111');
                this.timeQuantum = res
                this.tableLoading = false
            })
            // 获取场地列表
            getPlaceList().then((res) => {
                // console.log(res, '日历场地');
                this.siteName = res.data
                this.tableLoading = false
            })
        },
        // 获取日期
        getWeekList() {
            // 获取当前日期
            const today = new Date()
            const week = [] //周
            const md = [] // month + day 月 日
            for (let i = 0; i <= 6; i++) {
                const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() ? today.getDay() : 7) + i + 1 + this.dayNum);
                md[i] = this.fillZero(date.getMonth() + 1) + '-' + this.fillZero(date.getDate());
                let index = date.getDay();
                week[i] = "周" + "日一二三四五六".charAt(index);
            }
            let result = [];
            result['md'] = md;
            result['week'] = week;
            // console.log(md, 'md');
            // console.log(week, 'week');
            // console.log(result, 'result');
            this.md = result.md
            this.week = result.week

            // 获取当前日期是星期几（0 表示星期日，1 表示星期一，依此类推）
            const dayOfWeek = today.getDay() ? today.getDay() : 7
            // 获取当前日期所在星期的第一天（星期日）
            let firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek + 1 + this.dayNum)
            // console.log(firstDayOfWeek, 'firstDayOfWeekfirstDayOfWeekfirstDayOfWeek');
            // 获取当前日期所在星期的最后一天（星期六）
            const lastDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (this.dayNum + 7 - dayOfWeek))
            // 输出当前日期所在星期的年月日
            // console.log(`第一天：${firstDayOfWeek.getFullYear()}-${firstDayOfWeek.getMonth() + 1}-${firstDayOfWeek.getDate()}`)
            // console.log(`最后一天：${lastDayOfWeek.getFullYear()}-${lastDayOfWeek.getMonth() + 1}-${lastDayOfWeek.getDate()}`)
            let firstDay = `${firstDayOfWeek.getFullYear()}年${firstDayOfWeek.getMonth() + 1}月${firstDayOfWeek.getDate()}日`
            let lasttDay = `${lastDayOfWeek.getMonth() + 1}月${lastDayOfWeek.getDate()}日`
            this.firstDay = firstDay
            this.lasttDay = lasttDay
            // 将日期拼接成XXXX-XX-XX 并存起来
            let Yms = result.md.map((item) => `${firstDayOfWeek.getFullYear()}-${item}`)
            this.Yms = Yms
            // this.Yms = ["2023-05-22","2023-05-23","2023-05-24","2023-05-25","2023-05-26","2023-05-27","2023-05-29"]
            // console.log(this.Yms, 'this.Yms');
        },
        fillZero(n) {
            return n < 10 ? "0" + n : "" + n;
        },
        nextWeek() {
            this.dayNum += 7
            this.getWeekList()
            this.getTableList()
        },
        lastWeek() {
            this.dayNum -= 7
            this.getWeekList()
            this.getTableList()
        },
        // 场地切换
        handleSite(index, item) {
            item == '篮球场' ? item = 'basketball' : item = 'tennis'
            // console.log(item, 'item');
            this.siteType = item
            // console.log(this.basketball,'this.basketball');
            this.activeTab = index
            this.getTableList()
        },
        refresh() {
            // location.reload() // 刷新页面
        },
        // 打开预定场地弹窗
        handleFix() {
            // this.dialogVisible = true
            this.suggestionObj.id = null;
            this.suggestionObj.detailsList = null;
            this.suggestionObj.dialogStatus = "add";
            this.suggestionObj.dialogVisible = true;
            this.suggestionObj.dialogTitle = "新增会议预定";
        },

        handleClose(freshFlag) {
            this.suggestionObj.dialogVisible = false;
            freshFlag && this.getTableList();
        },
        handleCloseDialog() {
            this.dialogVisible2 = false;
            this.cancelchecked = false;
            this.$refs.ruleFormDialog.resetFields();
        },
        
        timeClick(i,j,k) {
            let destinedDate = Object.keys(i)[0]
            let siteName = Object.keys(j).toString()
            console.log(destinedDate,siteName,k);
            let detailsList = {
                destinedDate: destinedDate,
                siteName: siteName,
                k: k
            }
            this.suggestionObj.detailsList = detailsList
            this.suggestionObj.dialogStatus = "view";
            this.suggestionObj.dialogVisible = true;
            this.suggestionObj.dialogTitle = "查看会议预定";
            console.log(detailsList);
        },
        // 取消预定
        getRemoveOrderID() {
            this.dialogVisible2 = true
        },

    },
    computed: {
        filteredSiteName() {
            // return this.siteName.filter(item => item.siteType === this.siteType)
            return this.siteName
        }
    },
    watch: {
        timeQuantum: function (newVal, oldVal) {
            // 当数据发生变化时执行的操作
            this.timeQuantum = newVal
        }
    }
}
</script>
<style lang="less" scoped>
.stadium-calendar {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }

    .calendar-contant {
        .contantTitle {
            display: flex;
            justify-content: space-between;

            .contantTitle_Tabs {
                width: 160px;
                height: 50px;
                background-color: #F8F8F8;
                border: 5px solid #F8F8F8;

                button {
                    cursor: pointer;
                    display: inline-block;
                    height: 40px;
                    width: 50%;
                    line-height: 38px;
                    text-align: center;
                    // color: #909399;
                    font-size: 14px;
                    border: none;
                    background-color: #F8F8F8;
                }

                .action {
                    background-color: #fff;
                    color: blue;
                    border-radius: 5px;
                }
            }
        }
    }

    .contantTable {
        margin-top: 20px;
        // border: 0.5px solid #f5f5f5;

        table {
            border-collapse: collapse;

            width: 100%;
        }

        th,
        td {
            border: 1px solid #f2f2f2;
            padding: 8px 12px;
            height: 30px;
            line-height: 30px;
        }

        thead>tr:first-child>th:first-child {
            background-color: transparent;
        }

        th:first-child {
            width: 110px;
        }

        td {
            height: 100px;
            text-align: center;
            line-height: 100px;

            p {
                height: 20px;
                background-color: #1A4CEC;
                color: #FFFFFF;
                border-radius: 3px;
                line-height: 20px;
                margin: 5px 0;
                cursor: pointer;
            }
        }

        th {
            background-color: #f5f5f5;
            text-align: left;
        }

        // tbody tr:nth-child(even) {
        //     background-color: #f2f2f2;
        // }


    }

    .Dialog {
        .dialog-title {
            font-size: 12px;
            color: gray;
            margin-bottom: 5px;
        }

        .dialog-checkbox {
            color: gray;
            margin-top: 5px;
        }
    }

}

.light {
    background-color: #F4F6FE !important;
    color: #1A4CEC !important;
}

.grey {
    background-color: #F8F8F8 !important;
    color: #909399 !important;
}

.Popup {

    // 弹窗头部和底部固定
    :deep(.el-dialog) {
        height: 100%;

        .el-dialog__body {
            max-height: calc(100% - 154px);
            overflow-y: auto;
        }
    }
}
</style>